<template>
  <section>
    <!-- Pricing -->
    <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
      <!-- Title -->
      <div class="max-w-2xl mx-auto text-center mb-8">
        <h2
          class="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white"
        >
          99% of the features are available for
          <span
            class="bg-clip-text text-transparent lg:block bg-gradient-to-r from-pink-600 to-yellow-400"
            >free and without limits</span
          >
        </h2>
        <p class="mt-2 text-gray-600 dark:text-gray-400">
          You deserve a pricing strategy that aligns with your goals. We offer a
          range of pricing plans crafted to meet your unique needs.
        </p>
      </div>
      <!-- End Title -->

      <!-- Switch -->
      <div class="flex justify-center items-center">
        <MonthlyYearlySelector v-model="isYearly" />
      </div>
      <!-- End Switch -->

      <!-- Grid -->
      <div
        class="mt-12 grid sm:grid-cols-2 lg:grid-cols-3 gap-10 lg:items-center"
      >
        <!-- Card -->
        <div
          class="flex flex-col border-2 border-gray-200 text-center rounded-xl p-8 dark:border-gray-700"
        >
          <h3 class="text-2xl font-bold">Free</h3>

          <div class="text-md text-gray-400 mt-1">
            Create interactive forms that connect to your workflow, without
            limitations
          </div>

          <ul class="space-y-2 mt-7 text-sm sm:text-base">
            <li
              v-for="item in freePricingFeatures"
              :key="item?.title"
              class="flex space-x-3"
            >
              <span
                class="mt-0.5 size-5 flex justify-center items-center rounded-full bg-slate-600 text-white dark:bg-blue-800/30 dark:text-blue-500"
              >
                <svg
                  class="flex-shrink-0 size-3.5"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </span>

              <span class="text-gray-500 dark:text-gray-200">
                {{ item?.title }}
              </span>
            </li>
          </ul>

          <div class="mt-5">
            <v-button
              color="white"
              class="w-full hover:bg-white-500"
              :arrow="false"
              :to="!authenticated ? { name: 'auth-register' } : 'home'"
            >
              Get Started
            </v-button>
          </div>
        </div>
        <!-- End Card -->

        <!-- Card -->
        <div
          class="p-6 relative z-10 bg-slate-800 border rounded-xl md:p-6 dark:bg-slate-900 dark:border-gray-700"
        >
          <h3 class="text-2xl font-bold text-primary-600 dark:text-gray-200">
            Professional
          </h3>

          <div class="text-md text-gray-400 mt-1">
            Make your forms more beautiful and on-brand
          </div>

          <div class="mt-5">
            <span class="text-6xl font-bold text-white dark:text-gray-200">{{
              proPlanPrices
            }}</span>
            <span class="text-lg font-bold text-white dark:text-gray-200"
              >.00</span
            >
            <span class="ms-3 text-gray-400">USD / monthly</span>
          </div>

          <hr class="h-px mt-6 bg-gray-500 border-0 dark:bg-gray-700" />

          <div class="py-3 first:pt-0 last:pb-0">
            <!--Title-->
            <div class="py-2">
              <h4 class="font-medium text-lg text-gray-300 dark:text-gray-200">
                Features
              </h4>

              <div class="text-md text-gray-400">
                Everything in the free plan, plus:
              </div>
            </div>
            <!--End Title-->

            <!-- List -->
            <ul class="space-y-2 text-sm sm:text-base">
              <li
                v-for="item in professionalPricingFeatures"
                :key="item?.title"
                class="flex space-x-3"
              >
                <span
                  class="mt-0.5 size-5 flex justify-center items-center rounded-full bg-primary-600 text-white dark:bg-blue-800/30 dark:text-blue-500"
                >
                  <svg
                    class="flex-shrink-0 size-3.5"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                </span>
                <span class="text-gray-300 dark:text-gray-200">
                  {{ item?.title }}
                </span>
              </li>
            </ul>
            <!-- End List -->
          </div>

          <div class="py-2">

            <v-button
              v-if="!authenticated"
              class="w-full bg-primary-600 hover:bg-primary-800 dark:bg-primary-500"
              :to="{ name: 'auth-register' }"
              :arrow="true"
            >
              Start free trial
            </v-button>

            <v-button
              v-else-if="authenticated && user.is_subscribed"
              class="w-full bg-primary-600 hover:bg-primary-800 dark:bg-primary-500"
              :arrow="true"
              @click.prevent="openBilling"
            >
              View Billing
            </v-button>

            <v-button
              v-else
              class="w-full bg-primary-600 hover:bg-primary-800 dark:bg-primary-500"
              :arrow="true"
              @click.prevent="openCustomerCheckout('default')"
            >
              Start free trial
            </v-button>
          </div>
        </div>
        <!-- End Card -->

        <!-- Card -->
        <div
          class="flex flex-col border-2 border-gray-200 text-center rounded-xl p-8 dark:border-gray-700"
        >
          <h3 class="text-2xl font-medium">Custom Pricing</h3>

          <div class="text-md text-gray-400 mt-1">
            Unloack limitless growth with advanced features and dedicated
            support
          </div>

          <div class="py-5 flex justify-center items-center">
            <NuxtImg
              alt="Nice plant as we have nothing else to show!"
              src="/img/icons/toast.png"
              width="100"
            />
          </div>

          <ul class="space-y-2 text-sm sm:text-base">
            <li
              v-for="item in customPricingFeatures"
              :key="item?.title"
              class="flex space-x-3"
            >
              <span
                class="mt-0.5 size-5 flex justify-center items-center rounded-full bg-slate-600 text-white dark:bg-blue-800/30 dark:text-blue-500"
              >
                <svg
                  class="flex-shrink-0 size-3.5"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </span>

              <span class="text-gray-500 dark:text-gray-200">
                {{ item?.title }}
              </span>
            </li>
          </ul>

          <div class="mt-5">
            <v-button
              class="w-full bg-gray-900 hover:bg-gray-600 dark:bg-primary-500"
              :arrow="false"
            >
              Contact Sales
            </v-button>
          </div>
        </div>
        <!-- End Card -->
      </div>
      <!-- End Grid -->
    </div>
    <!-- End Pricing -->

    <!-- Modal -->
    <CheckoutDetailsModal
      :show="showDetailsModal"
      :yearly="isYearly"
      :plan="selectedPlan"
      @close="showDetailsModal = false"
    />
  </section>
</template>

<script setup>
import { useAuthStore } from "../../../stores/auth"
import MonthlyYearlySelector from "./MonthlyYearlySelector.vue"
import CheckoutDetailsModal from "./CheckoutDetailsModal.vue"

MonthlyYearlySelector.compatConfig = { MODE: 3 }

const customPricingFeatures = [
  {
    title: "Custom response limit",
  },
  {
    title: "VIP support and onboarding",
  },
  {
    title: "Custom branding and white-labeling",
  },
  {
    title: "Custom integrations",
  },
]

const professionalPricingFeatures = [
  {
    title: "Form confirmation emails",
  },
  {
    title: "Unlimited forms and submissions",
  },
  {
    title: "Editable submission data",
  },
  {
    title: "Remove Formify branding",
  },
  {
    title: "Custom domains",
  },
  {
    title: "Custom integrations",
  },
  {
    title: "Webhooks",
  },
]

const freePricingFeatures = [
  {
    title: "Unlimited forms and submissions",
  },
  {
    title: "Custom `Thank You` page",
  },
  {
    title: "Redirect on completion",
  },
  {
    title: "Form logic",
  },
  {
    title: "Schedule a close date",
  },
  {
    title: "Close on submission limit",
  },
]

const authStore = useAuthStore()

const { user, check: authenticated } = storeToRefs(authStore)

const isYearly = ref(false)
const selectedPlan = ref("pro")
const showDetailsModal = ref(false)

const proPlanPrices = computed(() => {
  return isYearly.value ? "$16" : "$18"
})

const openCustomerCheckout = (plan) => {
  selectedPlan.value = plan
  showDetailsModal.value = true
}
</script>
